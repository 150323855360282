import { useContext } from 'react';
import { usePathname } from 'next/navigation';
import NextImage from 'next/image';

import ClientContext from '@/contexts/client.context';

import getImgSrcWorkaround from '@/utils/images';
import { products } from '@/utils/products';

import { Heading, Paragraph } from '@swordhealth/ui-corporate';
import { getClient } from '@/services/client.service';
import { compareDates } from '@/utils/dates';
import { getOnboardingURL } from '@/utils/onboarding-urls';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

import { Wrapper, Card, CardInner, LogoWrapper, Content, Image, Button } from './styles';

export default function Cards({ cards, selectProduct, client = null, country = null, params }) {
  const { setProduct } = useContext(ClientContext);
  const pathname = usePathname();
  const isEligibilityCheckRoute = pathname.includes('/eligibility-check');

  const cardsWithoutImage = cards.filter(
    (card) => !getImgSrcWorkaround(card?.image?.url ?? card?.image?.data?.attributes.url),
  );

  const handleCardClick = async (item) => {
    const eligibilityURL = params
      ? `/eligibility-check?solution=${item.solution}&${params}`
      : `/eligibility-check?solution=${item.solution}`;

    if (!client) {
      selectProduct(products[item.solution.toUpperCase()]);
      setProduct(products[item.solution.toUpperCase()]);
      trackButtonClicked({
        action: ACTIONS.REDIRECT_TO_COVERAGE,
        buttonType: BUTTON_TYPES.NAVIGATION,
        buttonText: item?.button_label
          ? `${item?.button_label} - ${item.solution}`
          : `Get started - ${item.solution}`,
        destination: eligibilityURL,
        buttonLocation: 'card',
      });
      window.open(eligibilityURL, '_blank', 'noopener,noreferrer');
    } else {
      const fetchedClient = await getClient({
        client: client,
        productKey: item?.solution,
      });

      if (fetchedClient && compareDates(fetchedClient?.launchDate)) {
        return setTimeout(() => {
          window.open(
            `/waiting-list?client=${fetchedClient.clientKey}`,
            '_blank',
            'noopener,noreferrer',
          );
        }, 0);
      }
      const url = getOnboardingURL({
        client: fetchedClient,
        country: country,
        programType: products[item.solution.toUpperCase()].param,
        query: params,
      });

      trackButtonClicked({
        action: ACTIONS.REDIRECT_TO_ONBOARDING,
        buttonType: BUTTON_TYPES.NAVIGATION,
        buttonText: item?.button_label ?? `Get started - ${item.solution}`,
        destination: url,
        buttonLocation: 'form',
      });
      setTimeout(() => {
        window.open(url, '_blank', 'noopener,noreferrer');
      }, 0);
    }
  };

  return (
    <Wrapper $count={cards.length}>
      {cards.map((item, index) => (
        <Card
          key={item.solution}
          onClick={() => handleCardClick(item)}
          role="button"
          $white={isEligibilityCheckRoute}
          $numberOfCards={cards.length}
          id={`solution-${index}`}
        >
          <CardInner $withoutImage={cardsWithoutImage.length > 0}>
            <div>
              <LogoWrapper>
                {item.logo?.data && (
                  <NextImage
                    src={getImgSrcWorkaround(item?.logo?.url ?? item?.logo?.data?.attributes.url)}
                    alt=""
                    height={24}
                    width={59}
                    priority
                    id={`solution-logo-${index}`}
                  />
                )}
              </LogoWrapper>

              <Content $withoutImage={cardsWithoutImage.length > 0}>
                <Heading size="xl" as="h2" id={`solution-title-${index}`}>
                  {item.title}
                </Heading>
                {item.description && (
                  <Paragraph as="h3" size="sm" id={`solution-description-${index}`}>
                    {item.description}
                  </Paragraph>
                )}
              </Content>
              {cardsWithoutImage.length === 0 && item.image?.data && (
                <Image
                  src={getImgSrcWorkaround(item?.image?.url ?? item?.image?.data?.attributes.url)}
                  alt={item.title}
                  width={252}
                  height={604}
                  priority
                  id={`solution-image-${index}`}
                />
              )}
            </div>
            <div>
              <Button
                type="button"
                size="sm"
                target="_blank"
                rel="noopener noreferrer"
                id={`solution-button-${index}`}
              >
                {item?.button_label ?? 'Get started'}
                {cardsWithoutImage.length > 0 && (
                  <img src={'/assets/arrow_up.svg'} alt={'arrow_up'} />
                )}
              </Button>
            </div>
          </CardInner>
        </Card>
      ))}
    </Wrapper>
  );
}
